/*
 * @Description: 菜单
 * @Autor: lichao
 * @Date: 2022-01-18 09:13:15
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:25:02
 */
import logo from "../../static/img/logo@1.x.png";
import menu from "../../static/img/menu-btn.png";
import close from "../../static/img/menu-btn-close.png";
import { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./index.less";

const MenuList = [
  { name: "小镇介绍", path: "/home" },
  { name: "发展历程", path: "/memorabilia" },
  { name: "共享中心", path: "/sharecenter" },
  { name: "小镇动态", path: "/news" },
  { name: "公益基金会", path: "/foundation" },
  { name: "党建工作", path: "/partybuilding" },
];

const Menu = (props) => {
  const {
    location: { pathname },
  } = props;
  const [vis, setVis] = useState(0);
  const [activeNavPath, setActiveNavPath] = useState("");
  const getMenuList = (menuList) =>
    menuList.map((m, mi) => (
      <li
        key={mi}
        onClick={() => {
          document.body.style.position = "unset";
          setVis(!vis);
        }}
      >
        <NavLink
          to={m.path}
          activeClassName="menu-active"
          className={activeNavPath === m.path ? "menu-active" : ""}
        >
          {m.name}
        </NavLink>
      </li>
    ));

  useEffect(() => {
    let isNews =
      pathname.indexOf("news") > 0 ||
      pathname.indexOf("notice") > 0 ||
      pathname.indexOf("media") > 0 ||
      pathname.indexOf("focus") > 0;
    let isFou =
      pathname.indexOf("foundation") > 0 ||
      pathname.indexOf("introduce") > 0 ||
      pathname.indexOf("project") > 0 ||
      pathname.indexOf("info") > 0;
    if (isNews) setActiveNavPath("/news");
    else if (isFou) setActiveNavPath("/foundation");
    else setActiveNavPath(pathname);
  }, [pathname]);
  return (
    <header className="menu">
      <div className="menu-content">
        <img src={logo} alt="" />
        <img
          src={vis ? close : menu}
          alt=""
          onClick={() => {
            vis
              ? (document.body.style.position = "unset")
              : (document.body.style.position = "fixed");
            setVis(!vis);
          }}
        />
      </div>
      <ul
        className="menu-list"
        style={{
          display: vis ? "flex" : "none",
        }}
      >
        {getMenuList(MenuList)}
      </ul>
      <div
        className="menu-mask"
        onClick={() => {
          document.body.style.position = "unset";
          setVis(false);
        }}
        style={{ display: vis ? "block" : "none" }}
      ></div>
    </header>
  );
};
export default withRouter(Menu);
