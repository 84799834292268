/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-27 14:49:27
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-28 15:07:04
 */
import { useLocation } from 'react-router'
import {useEffect} from "react";
import Menu from "./components/Menu";
import MapFooter from "./components/MapFooter";
import { renderRoutes } from "./router/index";
import routes from "./router/routes";
import "./App.css";

function App() {
    useEffect(()=> {
        //友盟统计数据，单页面应用需要监听路由跳转
        const hm=document.createElement('script');
        hm.src="https://s4.cnzz.com/z_stat.php?id=1280170972&web_id=1280170972";
        const s=document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm,s)
    }, [useLocation()])
  return (
    <div className="App">
      <Menu></Menu>
      {renderRoutes(routes)}
      <MapFooter></MapFooter>
    </div>
  );
}

export default App;
