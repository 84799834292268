/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-27 14:49:27
 * @LastEditors: lichao
 * @LastEditTime: 2021-12-28 11:22:45
 */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import "lib-flexible";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
