/*
 * @Description:
 * @Autor: lichao
 * @Date: 2021-12-08 14:28:31
 * @LastEditors: lichao
 * @LastEditTime: 2022-01-19 15:15:21
 */

import { lazyLoad } from "./index";

// component
const Home = lazyLoad(() => import("../page/Home"));
const News = lazyLoad(() => import("../page/News"));
const Article = lazyLoad(() => import("../page/Article"));
const Partybuilding = lazyLoad(() => import("../page/Partybuilding"));
const Memorabilia = lazyLoad(() => import("../page/Memorabilia"));
const Sharecenter = lazyLoad(() => import("../page/Sharecenter"));
const Foundation = lazyLoad(() => import("../page/Foundation"));
const JoinForm = lazyLoad(() => import("../page/JoinForm"));

// routes
const routes = [
  {
    path: "/",
    exact: true,
    redirect: "/home",
    title: "北京基金小镇介绍",
  },
  {
    path: "/home",
    exact: false,
    component: Home,
    children: [],
    title: "北京基金小镇介绍",
  },
  {
    path: "/memorabilia",
    component: Memorabilia,
    exact: true,
    title: "北京基金小镇发展历程",
  },
  {
    path: "/sharecenter",
    component: Sharecenter,
    exact: true,
    title: "北京基金小镇共享中心",
  },
  {
    path: "/partybuilding/:id",
    component: Article,
    exact: true,
    title: "北京基金小镇党建工作",
  },
  {
    path: "/partybuilding",
    component: Partybuilding,
    title: "北京基金小镇党建工作",
    exact: true,
  },

  {
    path: "/news",
    component: News,
    title: "北京基金小镇动态",
    exact: false,
  },
  {
    path: "/notice/:id",
    component: Article,
    title: "公告通知",
    exact: true,
  },
  {
    path: "/media/:id",
    component: Article,
    title: "媒体报道",
    exact: true,
  },
  {
    path: "/focus/:id",
    component: Article,
    title: "小镇要闻",
    exact: true,
  },
  {
    path: "/foundation",
    component: Foundation,
    title: "北京基金小镇公益基金会",
    exact: false,
  },
  {
    path: "/foundation/introduce",
    component: Foundation,
    exact: false,
    meta: { name: "基金会简介" },
  },
  {
    path: "/foundation/project",
    focus: Foundation,
    exact: true,
    meta: { name: "项目动态" },
  },
  {
    path: "/project/:id",
    component: Article,
    exact: true,
  },
  {
    path: "/activity/:id",
    component: Article,
    exact: true,
  },
  {
    path: "/foundation/info",
    component: Foundation,
    exact: true,
    meta: { name: "信息公开" },
  },
  {
    path: "/info/:id",
    component: Article,
    exact: true,
  },
  {
    path: "/joinus",
    component: JoinForm,
    title: "加入我们",
    exact: false,
  },
];

export default routes;
