/*
 * @Description: 站点地图
 * @Autor: lichao
 * @Date: 2021-12-28 15:05:36
 * @LastEditors: lichao
 * @LastEditTime: 2022-02-17 15:24:57
 */
import code from "../../static/img/code.png";
import scan from "../../static/img/scan.png";
import phone from "../../static/img/phone.png";
import calendar from "../../static/img/calendar.png";
import "./index.less";
const MapFooter = () => (
  <div className="map-footer">
    <div className="top">
      <img src={code} alt="" />
      <ul className="contact">
        <li>
          <img src={scan} alt="" />
          <div>
            <span>手机扫一扫微信公众号</span>
            <br />
            <span>获取更多北京基金小镇动态</span>
          </div>
        </li>
        <li>
          <img src={phone} alt="" />
          <div>
            <span>注册咨询：</span>
            <br />
            <span>010-56860966、13911822501</span>
          </div>
        </li>
        <li>
          <img src={calendar} alt="" />
          <div>
            <span>工作时间： 周一至周五</span>
            <br />
            <span>(09:00-12:00 13:30-17:30)</span>
          </div>
        </li>
      </ul>
    </div>
    <div className="center">
      <ul className="link-list">
        <li>
          <a href="https://apply.beijingfundtown.com/">注册申请系统</a>
        </li>
        <li>
          <a href="http://m.toujiao.beijingfundtown.com">国家级投教基地</a>
        </li>
        <li>
          <a href="http://wap.beijingfundtown.com">云端·北京基金小镇</a>
        </li>
      </ul>
    </div>
    <div className="bottom">
      <p>
        互联网违法和不良信息投诉：010-56860668 COPYRIGHT © 2015-2024
        北京基金小镇 京ICP备16008758号-1
      </p>
      <p>增值电信业务经营许可证：京B2-20202691 京公网安备：11011102001195号</p>
    </div>
  </div>
);
export default MapFooter;
